import NationaleBold from "../../../Fonts/Nationale-Bold.otf"
import NationaleDemiBold from "../../../Fonts/Nationale-DemiBold.otf"
import NationaleLight from "../../../Fonts/Nationale-Light.otf"
import NationaleMedium from "../../../Fonts/Nationale-Medium.otf"
import NationaleRegular from "../../../Fonts/Nationale-Regular.otf"
import NationaleThin from "../../../Fonts/Nationale-Thin.otf"

const nationaleBold = {
  fontFamily: "Nationale",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: "600",
  src: `
   local('NationaleBold'),
   local('Nationale-Bold'),
   url(${NationaleBold}) format('otf')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

const nationaleDemiBold = {
  fontFamily: "Nationale",
  fontStyle: "demi-bold",
  fontDisplay: "swap",
  fontWeight: "600",
  src: `
 local('NationaleDemiBold'),
 local('Nationale-DemiBold'),
 url(${NationaleDemiBold}) format('otf')
`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

const nationaleLight = {
  fontFamily: "Nationale",
  fontStyle: "light",
  fontDisplay: "swap",
  fontWeight: "600",
  src: `
 local('NationaleLight'),
 local('Nationale-Light'),
 url(${NationaleLight}) format('otf')
`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

const nationaleMedium = {
  fontFamily: "Nationale",
  fontStyle: "medium",
  fontDisplay: "swap",
  fontWeight: "600",
  src: `
 local('NationaleMedium'),
 local('Nationale-Medium'),
 url(${NationaleMedium}) format('otf')
`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

const nationaleRegular = {
  fontFamily: "Nationale",
  fontStyle: "regular",
  fontDisplay: "swap",
  fontWeight: "600",
  src: `
 local('NationaleRegular'),
 local('Nationale-Regular'),
 url(${NationaleRegular}) format('otf')
`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

const nationaleThin = {
  fontFamily: "Nationale",
  fontStyle: "thin",
  fontDisplay: "swap",
  fontWeight: "600",
  src: `
 local('NationaleThin'),
 local('Nationale-Thin'),
 url(${NationaleThin}) format('otf')
`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

export const typography = {
  fontFamily: ["Nationale"].join(","),
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          nationaleBold,
          nationaleDemiBold,
          nationaleLight,
          nationaleMedium,
          nationaleRegular,
          nationaleThin,
        ],
      },
    },
  },
}
